import React from 'react'

import { BrowserProvider, useBrowserState } from './BrowserProvider'
import { MenuProvider, useMenuState } from './MenuProvider'
import { LightboxProvider, useLightboxState } from './LightboxProvider'

const ContextProvider = ({ children }) => (
    <BrowserProvider>
        <MenuProvider>
            <LightboxProvider>{children}</LightboxProvider>
        </MenuProvider>
    </BrowserProvider>
)

export { ContextProvider, useBrowserState, useMenuState, useLightboxState }
